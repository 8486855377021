import React, { useState } from "react";
import "./index.less";
import { HeroData } from '../../bean/ResponseBean'

interface IProps {
    item: HeroData
    occupation: {
        id: number,
        name: string,
        rarity: string,
        trait: string[]
        introductionInChinese: string,
        englishIntroduction: string
    },
    optionalOccupation: Map<number, number>
    chooseACareer: (item: HeroData) => void
}

const HeroCareer = ({ item, occupation, optionalOccupation, chooseACareer }: IProps) => {
    return (
        <div className={occupation.id === item.id ? 'selectable-image occupational-status' : "selectable-image"} onClick={() => chooseACareer(item)}>
            <img src={item.img} alt="" className={optionalOccupation.has(item.id) ? 'career-pictures' : 'career-pictures picture-grays-out'} style={{
                transform: `${item.id === 7 ? 'scale(1.3)' :
                    item.id === 8 ? 'scale(1.3)' :
                        item.id === 10 ? 'translateX(20%) scale(1.3)' :
                            item.id === 11 ? 'translateX(-15%) scale(1.3)' :
                                item.id === 12 ? 'translateX(-15%) scale(1.3)' :
                                    item.id === 13 ? 'translateX(-15%) scale(1.2)' :
                                        item.id === 15 ? 'translateX(-15%) scale(1.2)' :
                                            item.id === 16 ? 'scale(1.3)' :
                                                item.id === 17 ? 'translateX(5%)' : ''}`
            }} key={item.id} />

            <div className="career-shade" style={{
                background: `${optionalOccupation.has(item.id) ? item.rarity === 'Rare' ? 'linear-gradient(to top, #2f76fa, #2f76fa00)'
                    : item.rarity === 'Epic' ? 'linear-gradient(to top, #6a46ea, #6a46ea00)'
                        : item.rarity === 'Legendary' ? 'linear-gradient(to top, #fc9f42, #fc9f4200)'
                            : item.rarity === 'Myth' ? 'linear-gradient(to top, #c12d40, #c12d4100)' : '' : 'linear-gradient(to top, #878787, #87878700)'}`
            }}></div>
        </div>
    );
}

export default HeroCareer;
