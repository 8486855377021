import React, { useEffect, useState } from "react";
import { Web3Store } from "../../stores/web3Store";
import { Modal, Pagination, Button } from "antd";
import './index.less';
import close from '../../images/qzuki/close.png';
import Treasure from "../treasure/index";
import { Swiper, SwiperSlide } from 'swiper/react'
import "swiper/swiper.less";
import { getWalletString } from "../../utils/commonUtils";
import { logger } from "typechain/dist/utils/logger";
import NoData from "../noData/index";

const { Scrollbars } = require("react-custom-scrollbars");

interface IProps {
    web3Store: Web3Store
    treasures: any[]
    openTreasureModal: boolean
    loading: boolean
    closeModal: (type: string) => void
    callback?: (displayTreasure: object[]) => void
}

const TreasureModal = ({ treasures, closeModal, loading, callback, web3Store, openTreasureModal }: IProps) => {
    const [treasureQzukis, setTreasureQzukis] = useState(treasures)
    const [selectId, setSelectId] = useState(-1)
    const [scope, setScope] = useState(1)
    const [ifMobile, setIfMobile] = useState(false)
    const [allQzukis, setAllQzukis]: any[] = useState([])
    const [reserveQzukis, setReserveQzukis]: any[] = useState([])
    const [selectCondition, setSelectCondition] = useState('all')

    useEffect(() => {
        const getAllMyQzukis = async () => {
            const api = web3Store.mythServerApi;
            let wallet = getWalletString('wallet').wallet

            try {
                const getAllMyQzukis = await api.getAllMyQzukis(wallet)

                setAllQzukis(getAllMyQzukis)
                setReserveQzukis(getAllMyQzukis)
            } catch (e) {
                logger.error(e);
            }
        }

        getAllMyQzukis()

        changeScrollTopShow()
        window.addEventListener('resize', changeScrollTopShow);
        setTreasureQzukis(treasures)
        return () => {
            window.removeEventListener('resize', changeScrollTopShow)
        }
    }, [treasures])// eslint-disable-line

    const changeScrollTopShow = () => {
        if (window.innerWidth <= 950) {
            setIfMobile(true)
        } else if (window.innerWidth > 950) {
            setIfMobile(false)
        }
    };

    //隐藏react-custom-scrollbars组件的滚动条
    const renderThumb = ({ style, ...props }: any) => {//设置滚动条的样式
        const thumbStyle = {
            display: 'none'
        };
        return (
            <div
                style={{ ...style, ...thumbStyle }}
                {...props} />
        );
    }

    const selectDisplayArea = (id: number) => {
        setSelectId(id)
    }

    const selectToDisplay = (item: any) => {
        let ifRepeat = true

        treasureQzukis.forEach((t, i) => {
            if (t.thumbnail === item.thumbnail) {
                ifRepeat = false
            }
        })

        if (ifRepeat) {
            const exhibitQzukis = [...treasureQzukis]

            exhibitQzukis[selectId] = item
            setTreasureQzukis(exhibitQzukis)
            setSelectId(-1)
        }
    }

    const clearSelectedGraph = (id: number) => {
        const clearQzukis = [...treasureQzukis]
        clearQzukis[id] = {}
        setTreasureQzukis(clearQzukis)
    }

    const filterQzukis = (condition: string) => {
        let EligibleQzukis = []
        const reserveFilterQzukis = reserveQzukis

        if (condition === 'special') {
            EligibleQzukis = reserveFilterQzukis.filter((t: any) => {
                return t.special !== null
            })
        } else if (condition === 'all') {
            EligibleQzukis = reserveFilterQzukis
        } else if (condition === 'Red') {
            EligibleQzukis = reserveFilterQzukis.filter((t: any) => {
                return t.type === 'Red'
            })
        } else if (condition === 'Blue') {
            EligibleQzukis = reserveFilterQzukis.filter((t: any) => {
                return t.type === 'Blue'
            })
        } else if (condition === 'Spirit') {
            EligibleQzukis = reserveFilterQzukis.filter((t: any) => {
                return t.type === 'Spirit'
            })
        } else if (condition === 'Golden') {
            EligibleQzukis = reserveFilterQzukis.filter((t: any) => {
                if (t.clothing && t.clothing.indexOf('Golden') !== -1) {
                    return true
                } else if (t.neck && t.neck.indexOf('Golden') !== -1) {
                    return true
                } else if (t.offhand && t.offhand.indexOf('Golden') !== -1) {
                    return true
                }
                return false
            })
        } else if (condition === 'Element') {
            EligibleQzukis = reserveFilterQzukis.filter((t: any) => {
                if (t.hair && (t.hair.indexOf('Fire') !== -1 || t.hair.indexOf('Water') !== -1)) {
                    return true
                } else if (t.offhand && (t.offhand.indexOf('Earth') !== -1 || t.offhand.indexOf('Fireball') !== -1 || t.offhand.indexOf('Lightning Orb') !== -1 || t.offhand.indexOf('Water Orb') !== -1)) {
                    return true
                } else if (t.eyes && (t.eyes.indexOf('Fire') !== -1 || t.eyes.indexOf('Lightning') !== -1)) {
                    return true
                } else if (t.special && (t.special.indexOf('Fire') !== -1 || t.special.indexOf('Earth') !== -1 || t.special.indexOf('Water') !== -1 || t.special.indexOf('Lightning') !== -1)) {
                    return true
                }
                return false
            })
        }

        setAllQzukis(EligibleQzukis)
        setSelectCondition(condition)
        setScope(1)
    }

    const filter = ['all', 'special', 'Golden', 'Element', 'Red', 'Blue', 'Spirit']

    return (
        <Modal open={openTreasureModal} title="CLiCk NFT TO CHOOSE" onCancel={() => closeModal('treasure')} footer={null} className="treasure-modal" closeIcon={<img src={close} width={13} alt=""></img>} centered width={800}>
            <div className="Exhibit" >
                <div className="modal-display-nft">
                    {
                        treasureQzukis.map((item, index) => {
                            return <Treasure item={item} showSelectedStatus={selectId} ifModal={true} id={index} selectDisplayArea={selectDisplayArea} clearSelectedGraph={clearSelectedGraph}></Treasure>
                        })
                    }
                </div>
                <Button loading={loading} onClick={callback ? () => callback(treasureQzukis) : () => { }}>CONFIRM</Button>
                <div style={{ clear: 'both' }}></div>
            </div>
            <div className="selection-area">
                <div className="selection-btn">
                    {
                        ifMobile ?
                            <Swiper
                                className="Carousel"
                                slidesPerView='auto'
                                spaceBetween={5}
                            >
                                {
                                    filter.map((item) => {
                                        return <SwiperSlide>
                                            <Button className={selectCondition === item ? 'selectBtn' : ''} onClick={() => filterQzukis(item)}>{item.toUpperCase()}</Button>
                                        </SwiperSlide>
                                    })
                                }
                            </Swiper>
                            :
                            <>
                                {
                                    filter.map((item) => {
                                        return <Button className={selectCondition === item ? 'selectBtn' : ''} onClick={() => filterQzukis(item)}>{item.toUpperCase()}</Button>
                                    })
                                }
                            </>
                    }
                </div>
                <div className="selection-nft">
                    <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={200} style={{ width: '100%' }} renderThumbVertical={renderThumb}>
                        {
                            allQzukis.length !== 0 ?
                                <div className='gird-treasure-nft'>
                                    {
                                        ifMobile ?
                                            allQzukis.slice(scope * 20 - 20, scope * 20).map((item: any, index: any) => {
                                                return <img src={item.thumbnail} alt="" onClick={() => selectToDisplay(item)} className={`${treasureQzukis.find(t => { return t.id === item.id }) ? 'selected-status' : ''}`} />
                                            })
                                            :
                                            allQzukis.slice(scope * 16 - 16, scope * 16).map((item: any, index: any) => {
                                                return <img src={item.thumbnail} alt="" onClick={() => selectToDisplay(item)} className={`${treasureQzukis.find(t => { return t.id === item.id }) ? 'selected-status' : ''}`} />
                                            })
                                    }
                                </div>
                                : <div style={{ transform: 'scale(0.75)' }}><NoData noDatatext="You don't have this type of Qzuki NFT" textSize="15px" padding="0" /></div>
                        }
                    </Scrollbars>
                    <Pagination current={scope} onChange={(page) => setScope(page)} pageSize={ifMobile ? 20 : 16} total={allQzukis.length} showSizeChanger={false} />
                </div>
            </div>
        </Modal>
    );
};

export default TreasureModal;
