import {MerkleTree} from "merkletreejs";
import keccak256 from "keccak256";
import whitelist from "../data/whitelist.json";

export const getWalletProof = (walletAddress: string) => {
    const leafNodes = whitelist.whitelist.map(addr => keccak256(addr));
    const merkleTree = new MerkleTree(leafNodes, keccak256, {sortPairs: true});

    const claimingAddress = keccak256(walletAddress);
    const hexProof = merkleTree.getHexProof(claimingAddress);

    return hexProof;
};


export const checkInWhitelist = (walletAddress: string) => {
    if(walletAddress){
        const index = whitelist.whitelist.findIndex((value:any) => {
            return walletAddress.toLowerCase() === value.toLowerCase();
        });
        return index >= 0;
    }
    return false;
};

