import React, {lazy, ReactChild, Suspense} from "react";
import {useIsMobile} from "../effects";

const DarkTheme = lazy(() => import("./darkTheme"));

const DarkThemeMobile = lazy(() => import("./darkThemeMobile"));

interface IProps {
  children: ReactChild
}

export const ThemeProvider: React.FC<IProps> = ({ children }: IProps) => {
  const isMobile = useIsMobile()

  const renderDark = () => {
    if(isMobile) {
      return <>
        <DarkTheme />
        <DarkThemeMobile />
      </>
    }
    return <DarkTheme />
  }


  return (
    <>
      <Suspense fallback={<span />}>
        {renderDark()}
      </Suspense>
      {children}
    </>
  );
};
