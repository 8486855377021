import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { Signer } from 'ethers'
import { INetworkConfig } from '../env'

import { ILogger } from '../logger'
import { ConnectorNames, ConnectorProvider, IConnector } from '../web3Connectors'
import MythServerApi from "../api/MythServerApi";
import { Qzuki, Qzuki__factory, TestERC721, TestERC721__factory } from "../contracts";
import { SubGraphApi } from "../api/SubGraphApi";
import ContractProvider from "../api/ContractProvider";

export enum ChainEnum {
    EthereumMainnet = 1,
    EthereumRopsten = 3,
    EthereumRinkeby = 4,
    EthereumGoerli = 5,
    EthereumKovan = 42,
    MaticMumbai = 80001,
    MaticMainnet = 137,
    BSCTestnet = 97,
    BSCMainnet = 56,
    SolanaTestnet = 245022940,
}

export interface IChain {
    chainId: number
    name: string
}

export function getLocalStorageTokenKey(account: string) {
    return `qzuki_token_${account.toLowerCase()}`;
}

const chainName = (chainId: number): string => {
    if (chainId === ChainEnum.EthereumMainnet) {
        return "Mainnet";
    } else if (chainId === ChainEnum.EthereumRopsten) {
        return "Ropsten";
    } else if (chainId === ChainEnum.EthereumRinkeby) {
        return "Rinkeby";
    } else if (chainId === ChainEnum.EthereumGoerli) {
        return "Goerli";
    } else if (chainId === ChainEnum.EthereumKovan) {
        return "Kovan";
    } else if (chainId === ChainEnum.MaticMumbai) {
        return "Mumbai";
    } else if (chainId === ChainEnum.MaticMainnet) {
        return "Polygon";
    } else if (chainId === ChainEnum.BSCTestnet) {
        return "Chapel"
    } else if (chainId === ChainEnum.BSCMainnet) {
        return "BSC"
    } else if (chainId === ChainEnum.SolanaTestnet) {
        return "Solana testnet"
    }
    return "unknown network";
}

export interface IWeb3StoreParams {
    logger: ILogger,
    supportedNetworks: INetworkConfig[]
    connectorProvider: ConnectorProvider
}

export class Web3Store {

    account: string = '';
    chain: IChain | undefined = undefined;
    connectorName: ConnectorNames = ConnectorNames.Undefined
    isReady: boolean = false;
    signature: string = '';

    private readonly _logger: ILogger
    private _connector: IConnector | undefined
    private _connectorProvider: ConnectorProvider
    private readonly _supportedNetworks: { [id: number]: INetworkConfig }

    private chainChangeHandler(chainIdStr: string) {
        const chainId = parseInt(chainIdStr)
        this._logger.debug(`Web3Store.chainChangeHandler. NewChainId: ${chainId}`)
        runInAction(() => {
            this.chain = {
                chainId: chainId,
                name: chainName(chainId)
            }
        })
    }

    private accountChangeHandler(accounts: Array<string>) {
        // Handle the new accounts, or lack thereof.
        // "accounts" will always be an array, but it can be empty.
        if (accounts && accounts.length > 0 &&
            this.account && this.account.length > 0 &&
            accounts[0].toLowerCase() !== this.account.toLowerCase()) {
            window.location.reload()
        }
    }

    // internal method
    async __initAsync(connector: IConnector) {
        this.connectorName = connector.name
        this.account = ''
        this._connector = connector
        this.signature = ''
        const acc: string = await this._connector.signer.getAddress()
        const chainId: number = await this._connector.signer.getChainId()

        const that = this
        if (acc) {
            connector.onAccountsChanged((accounts: string[]) => that.accountChangeHandler.call(that, accounts))
            connector.onChainChanged((chainIdStr: string) => {
                that.chainChangeHandler.call(that, chainIdStr)
            })
            connector.onDisconnect(() => {
                that._logger.debug("Web3store. connector.onDisconnect")
                that.disconnect.call(that)
            })
        }
        runInAction(() => {
            if (acc) {
                this.account = acc.toLowerCase()
            }
            if (chainId) {
                this.chain = {
                    chainId: chainId,
                    name: chainName(chainId)
                }
            }
        })

    }

    constructor({ logger, connectorProvider, supportedNetworks }: IWeb3StoreParams) {
        this._logger = logger

        this._supportedNetworks = {}
        supportedNetworks.forEach(x => this._supportedNetworks[x.chainId] = x)


        this._connectorProvider = connectorProvider;

        connectorProvider.WaitInitialisationAsync.then(() => {
            if (connectorProvider.CurConnector) {
                this.__initAsync(connectorProvider.CurConnector).then(() => {
                    runInAction(() => {
                        this.isReady = true
                    })
                })
            } else {
                runInAction(() => {
                    this.isReady = true
                })
            }

        })

        makeObservable(this, {
            isReady: observable,
            account: observable,
            chain: observable,
            signature: observable,
            connectorName: observable,
            isConnectedToSupportedChain: computed,
            blockExplorer: computed,
            __initAsync: action.bound,
            connectAsync: action.bound,
            disconnect: action.bound
        });
    }

    get qzuki(): Qzuki | undefined {
        if (!this.chain) {
            return undefined
        }
        const addr = this._supportedNetworks[this.chain.chainId]?.qzukiContractAddr
        if (!addr) {
            return undefined
        }

        return this.signer ? Qzuki__factory.connect(addr, this.signer) : undefined
    }

    get azuki(): TestERC721 | undefined {
        if (!this.chain) {
            return undefined
        }
        const addr = this._supportedNetworks[this.chain.chainId]?.azukiContractAddr
        if (!addr) {
            return undefined
        }

        return this.signer ? TestERC721__factory.connect(addr, this.signer) : undefined
    }

    get beanz(): TestERC721 | undefined {
        if (!this.chain) {
            return undefined
        }
        const addr = this._supportedNetworks[this.chain.chainId]?.beanzContractAddr
        if (!addr) {
            return undefined
        }

        return this.signer ? TestERC721__factory.connect(addr, this.signer) : undefined
    }

    private _mythServerApi?: MythServerApi
    get mythServerApi(): MythServerApi {
        if (this._mythServerApi) {
            return this._mythServerApi
        }

        this._mythServerApi = new MythServerApi();
        return this._mythServerApi
    }

    private _subGraphApi?: SubGraphApi
    get subGraphApi(): SubGraphApi | undefined {
        if (!this.chain) {
            return undefined
        }

        const subGraphApiUrl = this._supportedNetworks[this.chain!.chainId]?.subGraphApiUrl
        if (!subGraphApiUrl) {
            this._subGraphApi = undefined
            return undefined
        }

        this._subGraphApi = new SubGraphApi(this._logger, subGraphApiUrl);
        return this._subGraphApi
    }

    private _contractProvider?: ContractProvider
    get contractProvider(): ContractProvider | undefined {
        if (!this.chain) {
            return undefined
        }

        if (this._contractProvider) {
            return this._contractProvider;
        }

        if (!this.qzuki || !this.subGraphApi) {
            return undefined;
        }

        this._contractProvider = new ContractProvider(this.qzuki, this.subGraphApi);
        return this._contractProvider;
    }


    private get signer(): Signer | undefined {
        return this._connector?.signer
    }

    get canSwitchChain(): boolean {
        if (!this._connector) {
            return false;
        }
        return this._connector.canSwitchChain
    }

    get isConnectedToSupportedChain(): boolean {
        if (!this.chain) {
            return false
        }
        return !!this._supportedNetworks[this.chain.chainId]
    }

    get supportedChains(): IChain[] {
        return Object.values(this._supportedNetworks).map(x => ({ chainId: x.chainId, name: chainName(x.chainId) }))
    }

    get chainName(): string {
        return chainName(this.chain!.chainId)
    }

    get blockExplorer(): string {
        if (!this.chain) {
            return ''
        }
        return this._supportedNetworks[this.chain?.chainId]?.blockExplorer
    }

    get currentNetworkConfig(): INetworkConfig | undefined {
        if (!this.chain) {
            return undefined
        }
        return this._supportedNetworks[this.chain?.chainId];
    }

    get supportedConnectors(): { [name: string]: IConnector } {
        return this._connectorProvider.connectorsByName
    }

    switchChain = (newChainId: number): Promise<void> => {
        if (this._connector) {
            return this._connector.switchChain(newChainId)
        }
        return Promise.resolve()
    }

    async connectAsync(connectorName: ConnectorNames): Promise<void> {
        await this._connectorProvider.EnableAsync(connectorName);
        if (this._connectorProvider.CurConnector) {
            await this.__initAsync(this._connectorProvider.CurConnector)
        }
    }

    async disconnect(): Promise<void> {
        this._logger.trace('web3store.disconnect')
        await this._connectorProvider.disconnect()
        this._connector = undefined
        runInAction(() => {
            this.account = ''
            this.chain = undefined
            this.connectorName = ConnectorNames.Undefined;
        })
    }

    async getSignatureMessage(message: string): Promise<string | undefined> {
        return this._connectorProvider.CurConnector?.signer.signMessage(message);
    }

    async signatureAsync(): Promise<boolean> {
        if (!this._mythServerApi) {
            this._mythServerApi = this.mythServerApi;
        }

        if (this._mythServerApi && this.account) {
            let response: any = await this._mythServerApi.getNonce(this.account);
            const signature = await this.getSignatureMessage(response.resultValue);
            if (signature) {
                response = await this._mythServerApi.requestLogin(this.account, signature);
                if (response) {
                    localStorage.setItem(getLocalStorageTokenKey(this.account), response.resultValue.token);
                    return true;
                }
            }
        }
        return false;
    }
}
