import React, {lazy} from 'react'
import "./connectWalletDesktopModal.less"

import {observer} from 'mobx-react';
import {Web3Store} from "../../stores/web3Store";
import {LazyLoader} from "../lazyLoader";

const Desktop = lazy(() => import("./connectWalletDesktopModal"));
const Mobile = lazy(() => import("./connectWalletMobileModal"));

interface IProps {
	web3Store: Web3Store
	visible: boolean
	onCancel: () => void
}

const Impl = ({onCancel, visible, web3Store }: IProps) => <LazyLoader
	desktop={<Desktop onCancel={onCancel} visible={visible} web3Store={web3Store} />}
	mobile={<Mobile onCancel={onCancel} visible={visible} web3Store={web3Store} />}
/>

export const ConnectWalletModal = observer(Impl);
