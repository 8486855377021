import React, {CSSProperties, useRef, useState} from "react"
import {Input} from "antd"
import SearchGolden from "../../images/qzuki-golden-icons/Search.png";
import Search from "../../images/qzuki-icons/Search.png";
import "./index.less";
import {debounceFunction} from "../../utils/debounce";

interface IProps {
    style?: CSSProperties | undefined;
    className?: string
    value?: string
    placeholder?: string
    onChange: (keywords: string) => void,
    isGolden?: boolean
}


export const SearchInput = ({value, placeholder, onChange, style, className, isGolden}: IProps) => {

    const [internalVal, setInternalVal] = useState(value);
    const onChangeDebounced = useRef(debounceFunction(onChange, 300)).current;

    const onChangeInternal = (term: string) => {
        setInternalVal(term)
        onChangeDebounced(term)
    };


    return <Input
        style={style}
        className={className ? className : "input"}
        bordered={false}
        value={internalVal}
        onChange={e => onChangeInternal(e.target.value)}
        prefix={isGolden === undefined ? "" : isGolden ? <img src={SearchGolden} alt=''/> : <img src={Search} alt=''/>}
        placeholder={placeholder}
        allowClear/>
};
