import React, { useEffect, useState } from "react";
import { Modal, Pagination, Button } from "antd";
import './index.less';
import close from '../../images/qzuki/close.png';
import Banner from '../personalCenterBanners/index'
import NoData from "../noData/index";
import { IPersonalInfoBanners } from "../../bean/ResponseBean";

const { Scrollbars } = require("react-custom-scrollbars");

interface IProps {
    banners: IPersonalInfoBanners[]
    ifSwitchBannerModal: boolean
    openBannerModal: boolean
    bannerTotal: number
    closeModal: (type: string) => void
    switchBanner?: (switchBanner: IPersonalInfoBanners) => void
}

const BannerModal = ({ banners, closeModal, switchBanner,bannerTotal, ifSwitchBannerModal, openBannerModal }: IProps) => {
    const [saveSwitchDiagram, setSaveSwitchDiagram]: any = useState()
    const [switchBannerId, setSwitchBannerId] = useState(-1)
    const [scope, setScope] = useState(1)

    //隐藏react-custom-scrollbars组件的滚动条
    const renderThumb = ({ style, ...props }: any) => {//设置滚动条的样式
        const thumbStyle = {
            display: 'none',
            opacity: 0,
        };
        return (
            <div
                style={{ ...style, ...thumbStyle }}
                {...props} />
        );
    }

    const selectBanner = (banner: IPersonalInfoBanners, id: number) => {
        setSaveSwitchDiagram(banner)
        setSwitchBannerId(id)
    }

    return (
        <Modal open={openBannerModal} title={'YOUR BANNERS (' + bannerTotal + ')'} onCancel={ifSwitchBannerModal ? () => closeModal('switch-banner') : () => closeModal('banner')} footer={null} className="banner-modal" closeIcon={<img src={close} width={13} alt=""></img>} centered width={1000}>
            {
                banners.length !== 0 ?
                    <>
                        < div className="modal-banners">
                            <Scrollbars autoHeight autoHeightMax={420} style={{ width: '100%' }} renderThumbVertical={renderThumb}>
                                <div className='banners-modal-gird'>
                                    {
                                        banners.slice(scope * 9 - 9, scope * 9).map((item: IPersonalInfoBanners, index: number) => {
                                            return <Banner banner={item} ifModal={true} selectBanner={selectBanner} index={index + scope * 9 - 9} switchBannerId={switchBannerId} ifSwitchBannerModal={ifSwitchBannerModal}></Banner>
                                        })
                                    }
                                </div>
                            </Scrollbars>
                            {
                                ifSwitchBannerModal ?
                                    <Button onClick={switchBanner && saveSwitchDiagram ? () => switchBanner(saveSwitchDiagram) : () => { }}>CONFIRM</Button> :
                                    ''
                            }
                        </div>
                        <Pagination defaultCurrent={1} onChange={(page) => setScope(page)} pageSize={9} total={banners.length} showSizeChanger={false} />
                    </>
                    : <NoData noDatatext="You don't have any Banner NFT"></NoData>}
        </Modal >
    );
};

export default BannerModal;
