import React from "react";
import "./index.less";

interface IProps {
    show: boolean;
    marginTop?: number;
    ifGolden: boolean
}

const LoadingView = ({ show, marginTop, ifGolden }: IProps) => {
    return (
        <div
            className="loading"
            style={{
                display: show ? "flex" : "none",
                position: 'relative',
                top: marginTop ? marginTop + 'px' : "0"
            }}
        >
            <div className='base'>
                <div className='cube' style={{ boxShadow: `${ifGolden ? '10em 10em 1.5em rgba(255, 255, 255, 0.1)' : '10em 10em 1.5em rgba(0, 0, 0, 0.1)'}` }}></div>
                <div className='cube' style={{ boxShadow: `${ifGolden ? '10em 10em 1.5em rgba(255, 255, 255, 0.1)' : '10em 10em 1.5em rgba(0, 0, 0, 0.1)'}` }}></div>
                <div className='cube' style={{ boxShadow: `${ifGolden ? '10em 10em 1.5em rgba(255, 255, 255, 0.1)' : '10em 10em 1.5em rgba(0, 0, 0, 0.1)'}` }}></div>
                <div className='cube' style={{ boxShadow: `${ifGolden ? '10em 10em 1.5em rgba(255, 255, 255, 0.1)' : '10em 10em 1.5em rgba(0, 0, 0, 0.1)'}` }}></div>
                <div className='cube' style={{ boxShadow: `${ifGolden ? '10em 10em 1.5em rgba(255, 255, 255, 0.1)' : '10em 10em 1.5em rgba(0, 0, 0, 0.1)'}` }}></div>
                <div className='cube' style={{ boxShadow: `${ifGolden ? '10em 10em 1.5em rgba(255, 255, 255, 0.1)' : '10em 10em 1.5em rgba(0, 0, 0, 0.1)'}` }}></div>
                <div className='cube' style={{ boxShadow: `${ifGolden ? '10em 10em 1.5em rgba(255, 255, 255, 0.1)' : '10em 10em 1.5em rgba(0, 0, 0, 0.1)'}` }}></div>
                <div className='cube' style={{ boxShadow: `${ifGolden ? '10em 10em 1.5em rgba(255, 255, 255, 0.1)' : '10em 10em 1.5em rgba(0, 0, 0, 0.1)'}` }}></div>
                <div className='cube' style={{ boxShadow: `${ifGolden ? '10em 10em 1.5em rgba(255, 255, 255, 0.1)' : '10em 10em 1.5em rgba(0, 0, 0, 0.1)'}` }}></div>
            </div>
            {/* <Image src={loadingImg} preview={false}></Image> */}
        </div >
    );
}

export default LoadingView;
