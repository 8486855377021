import { useEffect, useState } from "react";
import { Button, Modal, message } from "antd";
import { Web3Store } from "../../stores/web3Store";
import './qzukiModal.less'
import '../../styles/iconfont/font-eth/iconfont.css'
import { EffectCreative } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react'
import "swiper/swiper.less";
import logger from "../../logger";
import { goToTransaction } from '../../utils/commonUtils'
import opensea from '../../images/qzuki/opensea.png'
import looksrare from '../../images/qzuki/looksrare.png'
import element from '../../images/qzuki/element.png'
import x2y2 from '../../images/qzuki/x2y2.png'
import blur from '../../images/qzuki/blur.png'
import { getRoutePath, MythRouteId } from "../../router-config";
import { UserLastQzukiHero } from '../../bean/ResponseBean'
import {
    checkIconDark,
    convertAttributes,
    getBackgroundColor,
    parseTokenId
} from "../../constant/CommonConstant";
import LoadingView from "../loading";
import Logo from "../../images/qzuki/qzuki-data.png"
import LogoDack from "../../images/qzuki/qzuki-data-dark.png"
import logoBig from "../../images/qzuki/qzuki-hero.png"
import LogoBigDack from "../../images/qzuki/qzuki-hero-dark.png"
import HeroDataJson from '../../data/heroData.json'

const { Scrollbars } = require("react-custom-scrollbars");

interface IProps {
    web3Store: Web3Store;
    openGalleryModel: boolean
    closeGalleryModel: any
    qzukiData?: any
    qzukiId?: number
    ifDisplayHero?: boolean
}

const QzukiModal = ({ web3Store, closeGalleryModel, openGalleryModel, qzukiData, qzukiId, ifDisplayHero }: IProps) => {
    const [ifIconDark, setIfIconDark] = useState(false)
    const [ifSwiper, setIfSwiper] = useState(false)
    const [ifSwiperqOrh, setIfSwiperqOrh] = useState(false)
    const [owner, setOwner] = useState('')
    const [data, setData] = useState({ qzukiData: qzukiData });
    const [qzukiHero, setQzukiHero] = useState('qzuki')
    const [heroData, setHeroData] = useState<UserLastQzukiHero["hero"]>([{
        createWallet: '',
        heroName: '',
        heroPersonality: '',
        id: -1,
        occupationId: -1,
        qzukiId: -1,
        story: '',
        submitTime: '',
    }
    ])
    const [swiper, setSwiper] = useState<any>()

    useEffect(() => {
        const api = web3Store.mythServerApi

        async function executeIfQzukiDetailsReady(data: any) {
            if (checkIconDark(data.backgroundColor)) {
                setIfIconDark(true)
            } else {
                setIfIconDark(false)
            }

            try {
                let wallet = await api.getOwner(data.id);
                setOwner(wallet);

                if (qzukiId ? qzukiId : data.id && wallet) {
                    const getHeroData = await api.getUserLastQzukiHero(qzukiId ? qzukiId : data.id, wallet)

                    if (getHeroData.hero !== null) {
                        setHeroData(getHeroData.hero)
                        if (ifDisplayHero) {
                            setQzukiHero('hero')
                        }
                    }
                }
            } catch (e) {
                logger.error(e);
            }

            changeWidthSwipershow()
            window.addEventListener('resize', changeWidthSwipershow);
            return () => {
                window.removeEventListener('resize', changeWidthSwipershow);
            }
        }

        if (!data.qzukiData && qzukiId) {
            //personal page
            async function getQzukiDetails(qzukiId: number | undefined) {
                try {
                    if (qzukiId) {
                        const api = web3Store.mythServerApi;
                        const result = await api.getSingleQzuki(qzukiId);

                        if (result) {
                            const qzukiData = {
                                image: result.image,
                                backgroundColor: getBackgroundColor(result),
                                attributesConverted: convertAttributes(result),
                                id: parseTokenId(result.name)
                            };
                            setData({
                                qzukiData: qzukiData
                            });

                            executeIfQzukiDetailsReady(qzukiData);
                        }
                    }
                } catch (e) {
                    logger.error(e);
                }
            }

            getQzukiDetails(qzukiId);
        } else if (data.qzukiData && !qzukiId) {
            //gallery
            data.qzukiData.attributesConverted = convertAttributes(data.qzukiData);
            data.qzukiData.id = parseTokenId(data.qzukiData.name);
            executeIfQzukiDetailsReady(data.qzukiData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, ifDisplayHero, qzukiId]);

    const changeWidthSwipershow = () => {
        if (window.innerWidth <= 670) {
            setIfSwiper(true)
            setIfSwiperqOrh(true)
        } else if (window.innerWidth <= 930) {
            setIfSwiperqOrh(true)
        } else {
            setIfSwiper(false)
            setIfSwiperqOrh(false)
        }
    }

    const onCancel = () => {
        closeGalleryModel(false);
    };

    function renderContent() {
        return <>
            <div className="qzukiModal-img"><img src={data.qzukiData.image} alt="" /></div>
            <div className='information-bar'>
                <div className="qzuki-data-header flex-inline-row align-center space-between">
                    <div className="flex-inline-row align-center">
                        <span style={{ color: `${ifIconDark ? 'black' : 'white'}` }}>#</span>
                        <p style={{ color: `${ifIconDark ? 'black' : 'rgba(255, 255, 255, 0.6)'}` }}
                            className='qzuki-text'>QZUKI</p>
                        <p style={{ color: `${ifIconDark ? 'black' : 'white'}` }}>{data.qzukiData.id}</p>
                    </div>
                    <div className="flex-inline-row align-center qzuki-icon">
                        <a href={goToTransaction('opensea', data.qzukiData.id)} target="_blank"
                            rel="noopener noreferrer"><img
                                src={opensea} alt="" /></a>
                        <a href={goToTransaction('blur', data.qzukiData.id)} target="_blank"
                            rel="noopener noreferrer"><img src={blur}
                                alt="" /></a>
                        <a href={goToTransaction('element', data.qzukiData.id)} target="_blank"
                            rel="noopener noreferrer"><img
                                src={element} alt="" /></a>
                        <a href={goToTransaction('x2y2', data.qzukiData.id)} target="_blank"
                            rel="noopener noreferrer"><img src={x2y2}
                                alt="" /></a>
                        <a href={goToTransaction('looksrare', data.qzukiData.id)} target="_blank"
                            rel="noopener noreferrer"><img
                                src={looksrare} alt="" /></a>
                    </div>
                </div>

                <div className="qzuki-content">
                    {
                        !ifSwiper ?
                            data.qzukiData.attributesConverted && data.qzukiData.attributesConverted.map((item: any, index: number) => {
                                return <div key={index}
                                    className='content flex-row flex-direction-column justify-center'>
                                    <span
                                        style={{ color: `${ifIconDark ? 'black' : 'rgba(255, 255, 255, 0.6)'}` }}>{item.key.toUpperCase()}:</span>
                                    <p style={{ color: `${ifIconDark ? 'black' : 'white'}` }}>{item.value.toUpperCase()}</p>
                                </div>
                            }) :
                            <Swiper
                                modules={[EffectCreative]}
                                grabCursor={true}
                                initialSlide={0}
                                effect={"creative"}
                                creativeEffect={{
                                    prev: {
                                        shadow: true,
                                        translate: [0, 0, -400],
                                        opacity: 0,
                                    },
                                    next: {
                                        shadow: true,
                                        translate: ["100%", 0, 0],
                                        opacity: 1,
                                    },
                                }}
                            >
                                {
                                    data.qzukiData.attributesConverted && data.qzukiData.attributesConverted.map((item: any, index: number) => {
                                        return <SwiperSlide key={index}
                                            className='content flex-row flex-direction-column justify-center swiper-slide'>
                                            <span
                                                style={{ color: `${ifIconDark ? 'black' : 'rgba(255, 255, 255, 0.6)'}` }}>{item.key.toUpperCase()} :</span>
                                            <p style={{ color: `${ifIconDark ? 'black' : 'white'}` }}>{item.value.toUpperCase()}</p>
                                        </SwiperSlide>
                                    })
                                }
                            </Swiper>
                    }
                </div>

                {
                    owner ?
                        <div className="collector flex-row align-center" onClick={() => {
                            const url = window.location.origin + getRoutePath(MythRouteId.CollectorPage) + "?wallet=" + owner;
                            window.open(url, '_self');
                        }}>
                            <div className="iconfont icon-renwu-ren"
                                style={{ color: `${ifIconDark ? 'black' : 'white'}` }}></div>
                            <p style={{ color: `${ifIconDark ? 'black' : 'white'}` }}>{owner}</p>
                        </div> :
                        ifSwiper ? '' :
                            <div style={{ padding: '30px 0' }}>

                            </div>
                }
            </div>
        </>;
    }

    function renderHero() {
        return <div className="hero-information-backgruond">
            <div className="hero-img"  >
                <img src={HeroDataJson.hero.filter(item => item.id === heroData[0].occupationId)[0].img} alt="" />
            </div>
            <div className="hero-information">
                <div className="heroId flex-inline-row align-center">
                    <span style={{ color: `${ifIconDark ? 'black' : 'white'}` }}>#</span>
                    <p className='qzuki-text'>QZUKI</p>
                    <p>{heroData[0].qzukiId}</p>
                </div>
                <div className="heroName flex-inline-row flex-direction-column align-left" >
                    <p className='qzuki-text'>NAME</p>
                    <p>{heroData[0].heroName}</p>
                </div>
                <div className="heroOccupation flex-inline-row flex-direction-column align-left" >
                    <p className='qzuki-text'>OCCUPATION</p>
                    <p>{HeroDataJson.hero.filter(item => item.id === heroData[0].occupationId)[0].name}</p>
                </div>

                <div className="heroStory flex-inline-row flex-direction-column align-left" >
                    <p className='qzuki-text'>STROY</p>
                    <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={ifSwiperqOrh ? 100 : 300} style={{ width: '100%' }}>
                        <p>{heroData[0].story}</p>
                    </Scrollbars>
                </div>
            </div>
        </div>
    }

    function renderLoading() {
        return <LoadingView show={true} ifGolden={true} marginTop={-150} />
    }

    return (
        <Modal open={openGalleryModel} className='qzukiModal' footer={null} onCancel={onCancel} centered
            closable={window.innerWidth <= 930 ? true : false} width={1100}
            bodyStyle={{ backgroundColor: `${data.qzukiData?.backgroundColor}` }}>
            {
                !ifSwiperqOrh ?
                    data.qzukiData && data.qzukiData.id ?
                        qzukiHero === "hero" ?
                            renderHero() : renderContent()
                        : renderLoading()
                    : data.qzukiData && data.qzukiData.id ?
                        <Swiper
                            modules={[EffectCreative]}
                            grabCursor={true}
                            initialSlide={ifDisplayHero ? 1 : 0}
                            effect={"creative"}
                            creativeEffect={{
                                prev: {
                                    shadow: true,
                                    translate: [0, 0, -400],
                                    opacity: 0,
                                },
                                next: {
                                    shadow: true,
                                    translate: ["100%", 0, 0],
                                    opacity: 1,
                                },
                            }}
                            onSlideChange={() => ifSwiperqOrh && heroData[0].id === -1 ? swiper.slideTo(0) : ''}
                            onSwiper={(swiper) => setSwiper(swiper)}
                            className="Carousel"
                        >
                            <SwiperSlide key={1}>
                                {renderContent()}
                            </SwiperSlide>
                            <SwiperSlide key={2}>
                                {heroData[0].id !== -1 ? renderHero() : ''}
                            </SwiperSlide>
                        </Swiper>
                        : renderLoading()
            }

            {
                data.qzukiData && data.qzukiData.id ?
                    <div className="flex-row flex-direction-column q-h-btn">
                        <div className={qzukiHero === "qzuki" ? "qzuki magnify" : "qzuki"} onClick={() => setQzukiHero('qzuki')}> <img src={qzukiHero === "qzuki" ? LogoDack : Logo} alt="" /></div>
                        <div className={qzukiHero === "hero" ? "hero magnify" : "hero"} onClick={() => heroData[0].id !== -1 ? setQzukiHero('hero') : ''}> <img src={qzukiHero === "hero" ? LogoBigDack : logoBig} className={heroData[0].id === -1 ? "not-optional" : ""} alt="" /></div>
                    </div>
                    : ''
            }

            {/* <div className={qzukiHero === "qzuki" ? "amplifyA" : "qzuki-data-btn"} onClick={() => setQzukiHero('qzuki')}> <img src={qzukiHero === "qzuki" ? LogoDack : Logo} alt="" /></div>
            <div className={qzukiHero === "hero" ? "amplifyB" : "hero-data-btn"} onClick={() => setQzukiHero('hero')}> <img src={qzukiHero === "hero" ? LogoBigDack : logoBig } alt="" /></div> */}
        </Modal>

    );
};

export default QzukiModal;
