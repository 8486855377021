import {Qzuki} from "../contracts";
import {MintStage, TEAM_HOLD_NUM, WhiteListMintStateEnum} from "../constant/CommonConstant";
import {SubGraphApi} from "./SubGraphApi";
import {convertToNFTBalance, handleBalance, IMintAvailableBalance} from "../bean/NFTBalance";
import {checkInWhitelist} from "../utils/merkeltree";

export default class ContractProvider {
    private _qzuki: Qzuki;
    private _subgraphApi: SubGraphApi;

    constructor(qzuki: Qzuki, subGraphApi: SubGraphApi) {
        this._qzuki = qzuki;
        this._subgraphApi = subGraphApi;
    }

    public async getTotalRemain(): Promise<number> {
        return  await this._qzuki.getAvailable() + TEAM_HOLD_NUM;
        // return 2000;
    }

    public async getMintStatus(): Promise<MintStage> {
        const status = await this._qzuki.mintStatus();
        if (status === 1) {
            return MintStage.WhitelistMint;
        } else if (status === 2) {
            return MintStage.HolderMint;
        }
        if (status === 3) {
            return MintStage.PublicMint;
        }

        return MintStage.NotStarted;
    }

    public async getAzukiAndBeanzRemain(): Promise<number[]> {
        const remains = [];
        const azukiRemain = await this._qzuki.azukiRemainCount();
        const beanzRemain = await this._qzuki.beanzRemainCount();
        remains.push(azukiRemain);
        remains.push(beanzRemain);
        return remains;

        // return [1000,2000];
    }

    public async getMintAvailableBalance(wallet: string, azukiContractAddr: string, beanzContractAddr: string): Promise<IMintAvailableBalance> {
        const graphNFTBalance = await this._subgraphApi.fetchNFTBalance(wallet);
        const balance = convertToNFTBalance(graphNFTBalance, azukiContractAddr, beanzContractAddr);
        const finalBalance:IMintAvailableBalance = {azukiNum:balance.azukiNum,beanzNum:balance.beanzNum,azukiTokenIds: [], beanzTokenIds: [], canMintMax:0};
        if(balance.azukiTokenIds.length > 0){
            const azukiMintedResult = await this._qzuki.checkTokensMinted(balance.azukiTokenIds,true);
            azukiMintedResult.forEach((value,index) => {
                if(!value){
                    finalBalance.azukiTokenIds.push(balance.azukiTokenIds[index]);
                }
            });
        }
        if(balance.beanzTokenIds.length > 0){
            const beanzMintedResult =  await this._qzuki.checkTokensMinted(balance.beanzTokenIds,false);
            beanzMintedResult.forEach((value,index) => {
                if(!value){
                    finalBalance.beanzTokenIds.push(balance.beanzTokenIds[index]);
                }
            });
        }
        handleBalance(finalBalance);
        return finalBalance;

        // return {azukiTokenIds: [2,3,4],
        //     beanzTokenIds:[],
        //     canMintMax:3};
    }

    public async getWhitelistMintState(account: string): Promise<WhiteListMintStateEnum> {
        if (checkInWhitelist(account)) {
            const isMinted = await this._qzuki.whitelistMintedList(account);
            if (isMinted) {
                return WhiteListMintStateEnum.Minted;
            }
            return WhiteListMintStateEnum.MintAble;
        }

        return WhiteListMintStateEnum.NotWhitelist;
    }
}
