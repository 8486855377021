import React, { useState } from "react"
import { Checkbox, Menu } from "antd"
import "./index.less";
import { SearchInput } from "../searchInput";

interface IProps {
    openDrawer?: boolean,
    item: any,
    deleteTagSiderState: string[],
    selectFilter: (type: string, classification: string) => void
}

export const TraitsSubMenuContent = ({ item, openDrawer, deleteTagSiderState, selectFilter }: IProps) => {
    const [values, setTraitValues] = useState(item.children);

    const onSearchTraits = (keywords: string) => {
        const lcKeywords = keywords.toLowerCase();
        const searchResult = item.children.filter((item: any) => {
            return item.key.toLowerCase().indexOf(lcKeywords) > -1;
        });
        setTraitValues(searchResult);
    };

    return <div className="Scrollbars">
        {
            openDrawer ? '' :
                <SearchInput placeholder="Search..." className='search' onChange={keywords => {
                    onSearchTraits(keywords)
                }} />
        }
        {
            values.map((i: any) => {
                return <Menu.Item key={i.key}>
                    {
                        deleteTagSiderState[1] === i.key ?
                            <Checkbox checked={false} className="Checkbox"
                                onClick={() => selectFilter(item.name, i.key)}>{i.key.toUpperCase()}&nbsp;<span style={{color: '#b9b9b9'}}>({i.value})</span></ Checkbox>
                            : <Checkbox className="Checkbox"
                                onClick={() => selectFilter(item.name, i.key)}>{i.key.toUpperCase()}&nbsp;<span style={{color: '#b9b9b9'}}>({i.value})</span></ Checkbox>
                    }
                </Menu.Item>
            })
        }
    </div>
};
