import React, { Component } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { Web3Store } from "../stores/web3Store";
import '../styles/gallery.less'
import '../styles/siderAdaptation.less'
import { Layout, Menu, Switch, Button, Tag, Divider, Drawer } from "antd";
import { SyncOutlined, CloseOutlined, DownCircleFilled, UpCircleFilled } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import AnimatedNumber from "react-animated-numbers";
import logger from "../logger";
import LoadingView from "../components/loading";
import QzukiModal from '../components/galleryModal/qzukiModal'
import '../styles/iconfont/font-icon/iconfont.css'
import '../styles/iconfont/font-eth/iconfont.css'
import NavHeader from "../components/navHeader/index";
import { RootStore } from "../stores/rootStore";
import { SearchInput } from "../components/searchInput";
import { TraitsSubMenuContent } from "../components/traitsSubMenu";
import { getQueryString } from "../utils/commonUtils";
import { QzukiItem } from "../components/qzukiItem";
import { getRoutePath, MythRouteId } from "../router-config";
import { Swiper, SwiperSlide } from 'swiper/react'
import "swiper/swiper.less";
import {addBackgroundColor, convertAttributes} from "../constant/CommonConstant";

const { Scrollbars } = require("react-custom-scrollbars");

interface IProps {
    web3Store: Web3Store;
    rootStore: RootStore;
    isIndexGolden: any
}

interface IStates {
    isGolden: boolean
    siderMenu: any[]
    allQzukis: any[]
    total: number
    pageNum: number
    loading: boolean
    filterMap: Map<string, Map<string, string>>
    filterCriteria: any[]
    conditionsNum: any[]
    filterTotal: number
    deleteTagSiderState: string[]
    openDrawer: boolean
    keywords: string
    galleryModel: boolean
    qzukiData: any
    tokenId: string | undefined,
}

type IMarketProps = IProps & RouteComponentProps;

@observer
class Gallery extends Component<IMarketProps, IStates> {


    constructor(props: IMarketProps, state: IStates) {
        super(props, state);
        let tokenId = undefined;
        try {
            tokenId = getQueryString("id");
        } catch (e) {
            console.log(e)
        }
        this.state = {
            isGolden: false,
            siderMenu: [],
            allQzukis: [],
            total: 0,
            pageNum: 0,
            loading: true,
            filterMap: new Map(),
            filterCriteria: [],
            conditionsNum: [],
            filterTotal: 0,
            deleteTagSiderState: [],
            openDrawer: false,
            keywords: "",
            galleryModel: false,
            qzukiData: {},
            tokenId: tokenId,
        }
    }


    componentDidMount(): void {
        const { web3Store } = this.props;
        const { pageNum, filterCriteria, keywords, tokenId, isGolden } = this.state;

        if (this.localStorageGet('isGolden')) {
            this.setState({
                isGolden: true
            })
        }

        this.fetchGalleryData(this.localStorageGet('isGolden'), pageNum, filterCriteria, keywords, false, true, tokenId).then(value => {
            if (tokenId && web3Store.mythServerApi) {
                //pop up modal
                this.openGalleryModel(this.state.allQzukis[0]);
            }
        });
        this.fetchGallerySiderData(false);
    }

    convertGolden = () => {
        const { isIndexGolden } = this.props
        const { isGolden } = this.state
        this.setState({
            isGolden: !isGolden
        }, () => {
            this.localStorageSet('isGolden', !isGolden)
            this.fetchGalleryData(!isGolden, 0, this.state.filterCriteria, "", true, true)
            this.fetchGallerySiderData(!isGolden);
            isIndexGolden(!isGolden)
        })
    }

    localStorageSet = (name: string, data: boolean) => {
        const obj = {
            data,
            expire: new Date().getTime() + 1000 * 60 * 30
        };
        localStorage.setItem(name, JSON.stringify(obj));
    };

    //从localStorage处获取数据
    localStorageGet = (name: string) => {
        const storage = localStorage.getItem(name);
        const time = new Date().getTime();
        let result = false;
        if (storage) {
            const obj = JSON.parse(storage);
            if (time < obj.expire) {
                result = obj.data;
            } else {
                localStorage.removeItem(name);
            }
        }
        return result;
    };

    //侧边栏数据
    private async fetchGallerySiderData(siderIcons: boolean, keywords?: string, traitsKey?: string) {
        const { web3Store } = this.props
        const siderMenu: any[] = []

        const api = web3Store.mythServerApi;
        const allTraits = await api.getAllTraits();

        allTraits.forEach((item, index) => {
            const jsondata: any = {
                name: '',
                children: []
            }
            jsondata.name = index
            item.forEach((i, k) => {
                jsondata.children.push({
                    key: k,
                    value: i
                })
            })
            siderMenu.push(jsondata)
        })

        this.setState({
            siderMenu: siderMenu
        })
    }

    //获取qzuki数据
    private async fetchGalleryData(isGolden: boolean, page: number, filterCriteria: any[], keywords: string, ifClear: boolean, random: boolean, firstItemTokenId?: string) {
        const { web3Store } = this.props;
        const api = web3Store.mythServerApi;

        if (page === 1) {
            this.setState({ loading: true })
        }

        if (ifClear) {
            this.setState({
                allQzukis: []
            })
        }

        try {
            const result = await api.getList({
                filterGoldenMode: isGolden,
                tokenId: keywords,
                filterTraits: filterCriteria,
                pageNum: page + 1,
                pageSize: 100,
                random: random,
                firstItemTokenId: firstItemTokenId
            });

            let allNFTS = this.state.allQzukis.concat(JSON.parse(JSON.stringify(result.data)))

            allNFTS.forEach((item) => {
                addBackgroundColor(item);
            });

            this.setState({
                allQzukis: allNFTS,
                total: result.total,
                pageNum: page + 1,
                keywords: keywords,
                loading: false,
                filterCriteria: filterCriteria
            })
        } catch (e) {
            logger.error(e);
        }
    }

    //随机排序
    randomSort = () => {
        const { isGolden, filterCriteria, keywords } = this.state;
        this.fetchGalleryData(isGolden, 0, filterCriteria, keywords, true, true)
    }

    createAnimNumConfigs = (total: number) => {
        const length = total.toString().length;
        const configs = [];
        let tension = 200;
        for (let i = 0; i < length; i++) {
            tension += 20;
            const item = { mass: 1, tension: tension, friction: 90 };
            configs.push(item);
        }
        return configs;
    };

    onSearchByTokenId = (keywords: string) => {
        const { isGolden, filterCriteria } = this.state;
        this.fetchGalleryData(isGolden, 0, filterCriteria, keywords, true, true);
    };

    //筛选条件
    selectFilter = (type: string, classification: string) => {
        const { filterMap, keywords } = this.state
        const filterArray: any[] = []
        const conditionsNum: { key: string; size: number; }[] = []
        let total = 0

        if (filterMap.has(type)) {
            if (filterMap.get(type)?.has(classification)) {
                filterMap.get(type)?.delete(classification)
                if (filterMap.get(type)?.size === 0) {
                    filterMap.delete(type)
                }
            } else {
                filterMap.get(type)?.set(classification, classification)
            }
        } else {
            const valueMap = new Map()
            valueMap.set(classification, classification)
            filterMap.set(type, valueMap)
        }

        if (filterMap.size !== 0) {
            filterMap.forEach((item, index) => {
                const conditionTemplate: any = {
                    key: index,
                    values: []
                }
                const num = {
                    key: index,
                    size: 0
                }
                item.forEach((i) => {
                    conditionTemplate.values.push(i);
                })
                num.size = item.size
                conditionsNum.push(num)
                filterArray.push(conditionTemplate)
            })
            conditionsNum.forEach((item) => {
                total = total + item.size
            })
        }

        this.setState({
            conditionsNum: conditionsNum,
            filterTotal: total
        }, () => {
            this.fetchGalleryData(this.state.isGolden, 0, filterArray, keywords, true, true)
        })
    }

    //删除tag 侧边栏取消选中
    deleteTag = (key: string, value: string) => {
        const tag = []
        tag.push(key, value)
        this.setState({
            deleteTagSiderState: tag
        }, () => {
            this.selectFilter(key, value)
        })

        setTimeout(
            () => {
                this.setState({
                    deleteTagSiderState: []
                })
            }
            , 500);
    }

    //隐藏react-custom-scrollbars组件的滚动条
    renderThumb({ style, ...props }: any) {//设置滚动条的样式
        const thumbStyle = {
            display: 'none'
        };
        return (
            <div
                style={{ ...style, ...thumbStyle }}
                {...props} />
        );
    }

    openDrawer = () => {
        this.setState({
            openDrawer: true
        })
    }

    openGalleryModel = (item: any) => {
        if (item.attributes) {

            this.setState({
                galleryModel: true,
                qzukiData: item,
            })
        }
    }

    closeGalleryModel = (close: boolean) => {
        this.setState({
            galleryModel: close
        }, () => {
            const url = window.location.origin + getRoutePath(MythRouteId.Gallery);
            window.history.replaceState(null, "", url);
        })
    }

    render() {
        const { isGolden, allQzukis, total, qzukiData, pageNum, galleryModel, filterTotal, filterCriteria, openDrawer } = this.state
        const { Content } = Layout;
        const { rootStore } = this.props;

        return <>
            <NavHeader web3Store={rootStore.web3Store} backgroundColor={'#f8e51a'} padding={'calc(2vw + 10px)'} />

            <div className={`gallery flex-inline-row ${isGolden ? 'blackBody' : ''}`}>
                {this.renderSider('25%', '65px', '15px 10px 15px 20px')}
                <Content id="scrollableDiv" style={{ width: '100%', height: 'calc(100% - 90px)', overflow: 'auto' }}>
                    <div
                        className={`flex-inline-row content-header ${isGolden ? 'content-golden-header' : ''}`}>
                        <h1 className="flex-row align-center">QZUKI</h1>
                        <div className="flex-inline-row align-center total">
                            <span>
                                <AnimatedNumber
                                    animateToNumber={total}
                                    configs={this.createAnimNumConfigs(total)}
                                />
                            </span>
                            <Button onClick={this.randomSort}><SyncOutlined className="SyncOutlined" /></Button>
                            <div className="iconfont icon-bg-screen icon" style={{ color: `${isGolden ? 'white' : 'black'}` }}
                                onClick={this.openDrawer}></div>
                        </div>
                    </div>

                    <div className="filter flex-inline-row align-center">
                        <div className="filter-num">FILTERS</div>
                        <Swiper
                            className="Carousel"
                            slidesPerView='auto'
                            spaceBetween={10}
                        >
                            {
                                filterCriteria.map((item) => {
                                    return item.values.map((i: any, k: React.Key | null | undefined) => {
                                        return <SwiperSlide><Tag key={k} onClick={() => this.deleteTag(item.key, i)}>{item.key}: {i}
                                            <CloseOutlined style={{ fontSize: '10px', color: '#999999' }} /></Tag>
                                        </SwiperSlide>
                                    })
                                })
                            }
                        </Swiper>
                    </div>

                    {this.renderList(allQzukis, pageNum, total)}
                </Content>
                <Drawer
                    placement="right"
                    extra={<CloseOutlined onClick={() => this.setState({ openDrawer: false })} />}
                    closable={false}
                    title={<div style={{ color: `${isGolden ? 'white' : ''}`, fontWeight: '900' }}>FILTERS</div>}
                    width={'80%'}
                    headerStyle={{
                        padding: '20px',
                        backgroundColor: `${isGolden ? 'black' : ''}`,
                        color: `${isGolden ? 'white' : ''}`,
                        borderBottom: `${isGolden ? '#2e2e2e' : ''}`
                    }}
                    bodyStyle={{ padding: '0 10px 0 20px', backgroundColor: `${isGolden ? 'black' : ''}` }}
                    onClose={() => this.setState({ openDrawer: false })}
                    open={openDrawer}
                    className='siderDrawer'
                >
                    {this.renderSider('100%', '0px', '', 'calc(100% - 10px)')}
                </Drawer>

                {
                    galleryModel ?
                        <QzukiModal web3Store={this.props.web3Store} qzukiData={qzukiData} openGalleryModel={galleryModel}
                            closeGalleryModel={this.closeGalleryModel}/> : ""
                }

            </div >
        </>
    }


    private renderList(allQzukis: any[], pageNum: number, total: number) {
        const { filterCriteria, isGolden, keywords, loading } = this.state

        return <InfiniteScroll
            style={{ overflow: 'none' }}
            dataLength={allQzukis.length}
            next={() => this.fetchGalleryData(isGolden, pageNum, filterCriteria, keywords, false, false)}
            hasMore={allQzukis.length < total}
            loader={
                <LoadingView show={loading} ifGolden={isGolden} />
            }
            endMessage={loading ? <LoadingView show={true} ifGolden={isGolden} /> : allQzukis.length > 0 ? "" :
                <Divider plain style={{ color: `${isGolden ? 'white' : ''}` }}>No Qzuki matched your filter
                    parameters.</Divider>}
            scrollableTarget="scrollableDiv"
        >
            <div className="allNFTS">
                {
                    allQzukis.map((item, index) => {
                        return <QzukiItem key={"item-" + index} index={index} item={item} openGalleryModel={this.openGalleryModel} RouteId={MythRouteId.Gallery} />
                    })
                }
            </div>
        </InfiniteScroll>;
    }

    //侧边栏
    private renderSider(width: string, height: string, padding: string, siderHeight?: string) {
        const { siderMenu, isGolden, conditionsNum, deleteTagSiderState, openDrawer } = this.state
        const { Sider } = Layout;

        return <Sider theme='light' width={width}
            className={`${isGolden ? 'Adaptation siderBlack' : 'Adaptation'}`}
            style={{ height: `${siderHeight ? siderHeight : 'calc(98% - 90px)'}` }}>
            <h1>FILTER</h1>

            <Scrollbars renderThumbVertical={this.renderThumb} autoHide
                style={{ width: '100%', height: `calc(100% - ${height} )` }}>
                <div className="goldentext flex-inline-row align-center space-between">
                    <div className="flex-inline-row align-center goldentext-text">
                        GOLDEN MODE
                    </div>
                    <Switch onClick={this.convertGolden} checked={isGolden} />
                </div>
                <SearchInput placeholder="SEARCH" onChange={keywords => {
                    this.onSearchByTokenId(keywords)
                }} isGolden={isGolden} style={{ padding: padding }} />
                <Menu mode="inline" className='flex-row flex-direction-column align-left menu' expandIcon={
                    (e) => {
                        if (!e.isOpen) {
                            return <DownCircleFilled className="icon-bold" />
                        } else {
                            return <UpCircleFilled className="icon-bold" />
                        }
                    }
                }>
                    {
                        siderMenu.map((item) => {
                            return <Menu.SubMenu
                                title={<div className="flex-inline-row  align-center">{item.name.toUpperCase()}
                                    <div>
                                        {
                                            conditionsNum.map((a, b) => {
                                                if (a.key === item.name) {
                                                    return <div className="filterNum" key={b}>{a.size}</div>
                                                }
                                                return ''
                                            })
                                        }
                                    </div>
                                </div>}
                                key={item.name}
                            >
                                <TraitsSubMenuContent openDrawer={openDrawer} item={item}
                                    deleteTagSiderState={deleteTagSiderState}
                                    selectFilter={this.selectFilter} />
                            </Menu.SubMenu>
                        })
                    }
                </Menu>
            </Scrollbars>
        </Sider >
    }
}

export default withRouter(Gallery);
