import {IGraphNFTBalance} from "./ResponseBean";

export interface IMintAvailableBalance {
    azukiNum:number,
    beanzNum:number,
    azukiTokenIds: number[],
    beanzTokenIds: number[],
    canMintMax: number
}

export function convertToNFTBalance(graphNFTBalance: IGraphNFTBalance[] | undefined, azukiContractAddr: string, beanzContractAddr: string): IMintAvailableBalance {
    const balance: IMintAvailableBalance = {azukiNum:0,beanzNum:0,azukiTokenIds: [], beanzTokenIds: [],canMintMax:0};
    if (graphNFTBalance && graphNFTBalance.length > 0) {
        //classify
        graphNFTBalance.forEach(value => {
            const tokenId = Number.parseInt(value.tokenId);
            if (value.tokenAddress.toLowerCase() === azukiContractAddr.toLowerCase()) {
                balance.azukiTokenIds.push(tokenId)
            } else if (value.tokenAddress.toLowerCase() === beanzContractAddr.toLowerCase()) {
                balance.beanzTokenIds.push(tokenId);
            }
        });
        balance.azukiNum = balance.azukiTokenIds.length;
        balance.beanzNum = balance.beanzTokenIds.length;
    }
    return balance;
}

export function handleBalance(balance: IMintAvailableBalance): IMintAvailableBalance {
    //sort after classify
    balance.azukiTokenIds.sort((a, b) => a - b);
    balance.beanzTokenIds.sort((a, b) => a - b);
    balance.canMintMax = balance.azukiTokenIds.length * 2 + balance.beanzTokenIds.length;
    return balance;
}

