import React from "react"
import "./index.less";
import { getRoutePath, MythRouteId } from "../../router-config";

interface IProps {
    index: number,
    item: any,
    RouteId: number,
    openGalleryModel(item: any): void
    ifPersonalQzukiItem?: boolean
}


export const QzukiItem = ({ index, item, RouteId, openGalleryModel, ifPersonalQzukiItem }: IProps) => {
    return <>
        {
            ifPersonalQzukiItem ?
                <div className='NFT' key={index} onClick={() => {
                    openGalleryModel(item.id);
                }}>
                    <div className="Image"> <img
                        src={item.thumbnail} alt="" /></div>
                    <p>QZUKI</p>
                    <h3>NO.{item.id}</h3>
                </div >
                :
                <div className='NFT' key={index} onClick={() => {
                    openGalleryModel(item);
                    const tokenId = item.name.substring(item.name.indexOf("#") + 1);
                    const url = window.location.origin + getRoutePath(RouteId) + "?id=" + tokenId;
                    window.history.replaceState(null, "", url);
                }}>
                    <div className="Image" style={{ backgroundColor: `${item.backgroundColor}` }}> <img
                        src={item.thumbnail} alt="" /></div>
                    <p>QZUKI</p>
                    <h3>NO.{item.name.split(' ')[1].split('#')}</h3>
                </div >
        }
    </>
};
