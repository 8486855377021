import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import './index.less';
import close from '../../images/qzuki/close.png';
import Badge from "../badges/index";
import { Web3Store } from "../../stores/web3Store";
import { logger } from "typechain/dist/utils/logger";
import { IAllBadges } from "../../bean/ResponseBean";
import NoData from "../noData/index";
const { Scrollbars } = require("react-custom-scrollbars");

interface IProps {
    web3Store: Web3Store
    Badges: IAllBadges[]
    openBadgeModal: boolean
    externalSelectionBadge: IAllBadges
    closeModal: (type: string) => void
}

const BadgeModal = ({ Badges, closeModal, web3Store, openBadgeModal, externalSelectionBadge }: IProps) => {
    const [selectBadge, setSelectBadge]: any = useState()

    useEffect(() => {
        if (JSON.stringify(externalSelectionBadge) !== "{}" && externalSelectionBadge) {
            setSelectBadge(externalSelectionBadge)
        } else {
            setSelectBadge(Badges[0])
        }
    }, [Badges, externalSelectionBadge])

    const selectDisplayBadge = (item: IAllBadges) => {
        setSelectBadge(item)
    }

    return (
        <Modal open={openBadgeModal} title="ACHiEVEMENTS" onCancel={() => closeModal('badge')} footer={null} className="badge-modal" closeIcon={<img src={close} width={13} alt=""></img>} centered width={800}>
            {Badges.length !== 0 ? <>
                <div className="badge-modal-left">
                    <Scrollbars style={{ width: '100%', hight: '100%', marginLeft: `${window.innerWidth <= 950 ? '' : '-1%'}` }}>
                        <div className='badge-gird'>
                            {
                                Badges.map((item: IAllBadges, index: any) => {
                                    return <Badge badge={item} selectBadge={selectDisplayBadge}></Badge>
                                })
                            }
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </Scrollbars>
                </div>
                <div className="badge-modal-right flex-row flex-direction-column align-center justify-center">
                    {selectBadge ? <>
                        <img src={selectBadge.lightImage ? selectBadge.lightImage : selectBadge.darkImage} className={selectBadge.rarity === 'Rare' ? 'img-rare' : selectBadge.rarity === 'Legendary' ? 'img-Legendary' : 'img-Mythical'} alt="" />
                        <p>{selectBadge.name}</p>
                        <div className="badge-tag">{selectBadge.rarity}</div>
                        <div className="badge-information">{selectBadge.description}</div>
                    </> : ''
                    }
                </div>
            </> : <NoData noDatatext="No Badge NFT available"></NoData>
            }
        </Modal>
    );
};

export default BadgeModal;
