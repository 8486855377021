import React, { useState } from 'react';
import { ConnectWalletModal } from "../connectWalletModal";
import { observer } from 'mobx-react';
import { Web3Store } from "../../stores/web3Store";
import { toMasskedAddress } from "../../extensions";

interface IProps {
    web3Store: Web3Store
    callback?: any
}

export const NoConnectedWalletImpl = ({ web3Store, callback }: IProps) => {
    const [isWalletVisible, setIsWalletVisible] = useState(false)

    const renderWalletModal = () => <ConnectWalletModal
        web3Store={web3Store}
        visible={isWalletVisible}
        onCancel={() => showWalletTest()}
    />;

    const showWalletTest = () => {
        setIsWalletVisible(!isWalletVisible);
        if (callback) {
            callback()
        }
    };

    const renderConnectButton = () => {
        let content = "Connect";

        if (web3Store.chain && web3Store.account) {
            if (web3Store.isConnectedToSupportedChain) {
                content = toMasskedAddress(web3Store.account);
            } else {
                content = "Wrong ChainNet"
            }
        }

        return <div onClick={showWalletTest} style={{ width: '100%' }}>{content}</div>
    };

    return <>
        {renderConnectButton()}
        {renderWalletModal()}
    </>
}

export const ConnectedWallet = observer(NoConnectedWalletImpl)
