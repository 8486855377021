import React, { useState } from "react";
import "./index.less";
import { UserAllQzukiHero } from '../../bean/ResponseBean'
import HeroData from '../../data/heroData.json'

interface IProps {
    item: UserAllQzukiHero
}

const CollectorCenterCareer = ({ item }: IProps) => {
    const data = HeroData.hero.filter(i => i.id === item.occupationId)[0]

    return <>
        <div className="selectable-image" style={{
            border: `3px solid ${data.rarity === 'Rare' ? '#2f76fa'
                : data.rarity === 'Epic' ? '#6a46ea'
                    : data.rarity === 'Legendary' ? '#fc9f42'
                        : data.rarity === 'Myth' ? '#c12d40' : ''}`
        }}>
            <img src={data.img} alt="" className='career-pictures' style={{
                transform: `${data.id === 7 ? 'scale(1.3)' :
                    data.id === 8 ? 'scale(1.3)' :
                        data.id === 10 ? 'translateX(20%) scale(1.3)' :
                            data.id === 11 ? 'translateX(-15%) scale(1.3)' :
                                data.id === 12 ? 'translateX(-15%) scale(1.3)' :
                                    data.id === 13 ? 'translateX(-15%) scale(1.2)' :
                                        data.id === 15 ? 'translateX(-15%) scale(1.2)' :
                                            data.id === 16 ? 'scale(1.3)' :
                                                data.id === 17 ? 'translateX(5%)' : ''}`
            }} key={data.id} />

            <div className="career-shade" style={{
                background: `${data.rarity === 'Rare' ? 'linear-gradient(to top, #2f76fa, #2f76fa00)'
                    : data.rarity === 'Epic' ? 'linear-gradient(to top, #6a46ea, #6a46ea00)'
                        : data.rarity === 'Legendary' ? 'linear-gradient(to top, #fc9f42, #fc9f4200)'
                            : data.rarity === 'Myth' ? 'linear-gradient(to top, #c12d40, #c12d4100)' : ''}`
            }}></div>
        </div>

        <div className="collector-center-career">
            <p>{item.heroName}</p>
            <h3>NO.{item.qzukiId}</h3>
        </div>
    </>;
}

export default CollectorCenterCareer;
