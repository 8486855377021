import React, { Component } from "react";
import { Button, message } from 'antd';
import { observer } from "mobx-react";
import { Web3Store } from "../stores/web3Store";
import "../styles/qzukiHomePage.less"
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { MintStage } from "../constant/CommonConstant";
import { logger } from "typechain/dist/utils/logger";
import NavHeader from "../components/navHeader/index";
import { RootStore } from "../stores/rootStore";
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react'
import "swiper/swiper.less";

interface IProps extends RouteComponentProps {
    web3Store: Web3Store
    rootStore: RootStore;
}

interface IStates {
    isMintModel: boolean
    mintStage: MintStage | undefined
    btnLoading: boolean
    ifShowMobileSwiper: boolean
    firstLogin: boolean
    swiperImgArray: string[]
}

@observer
class HomePage extends Component<IProps, IStates> {
    constructor(props: IProps, state: IStates) {
        super(props);
        this.state = {
            isMintModel: false,
            mintStage: undefined,
            btnLoading: false,
            ifShowMobileSwiper: false,
            firstLogin: false,
            swiperImgArray: []
        }
    }

    componentDidMount(): void {
        this.changeScrollTopShow()
        this.random()
        window.addEventListener('resize', this.changeScrollTopShow);
    }

    changeScrollTopShow = () => {
        if (window.innerWidth <= 800) {
            this.setState({
                ifShowMobileSwiper: true,
                firstLogin: true
            });
        } else if (window.innerWidth > 800) {
            this.setState({
                ifShowMobileSwiper: false,
                firstLogin: true
            });
        }
    };

    //卸载事件监听
    componentWillUnmount() {
        window.removeEventListener("resize", this.changeScrollTopShow);
    }

    closeMintModel = (close: boolean) => {
        this.setState({
            isMintModel: close
        })
    }

    random = () => {
        const randomArray = []
        for (let i = 0; i < 28; i++) {
            randomArray.push('https://qzuki.myth.art/avatarsThumbnails/' + Math.floor(Math.random() * 10000) + '.png')
        }
        this.setState({
            swiperImgArray: randomArray
        })
    }


    render() {
        const { ifShowMobileSwiper, firstLogin, swiperImgArray } = this.state
        const { rootStore } = this.props;

        const swiperSpeed = [{
            time: 6000,
            ifreverseDirection: true,
            swiperImg: swiperImgArray.slice(0, 7)
        }, {
            time: 3000,
            ifreverseDirection: false,
            swiperImg: swiperImgArray.slice(7, 14)
        }, {
            time: 4000,
            ifreverseDirection: true,
            swiperImg: swiperImgArray.slice(14, 21)
        }, {
            time: 5000,
            ifreverseDirection: false,
            swiperImg: swiperImgArray.slice(21, 28)
        }]

        return <div className="home-page">
            <div className="home-page-left flex-row flex-direction-column align-center">
                <div style={{ width: '100%', position: 'relative' }}><NavHeader web3Store={rootStore.web3Store} /></div>

                <div className={`home-page-text ${firstLogin ? 'transition' : ''}`}>
                    <h1>Join Qzuki and Meet <br /> Your Q-version <span>NFTS</span></h1>
                    <p>We are creating a Q version of the NFT world to help people get into Web3 quickly. Qzuki is a multi-
                        functional PASS. It will also be a platform of Q-version brands.</p>
                    <Link to={'/gallery'}>
                        <Button>Gallery</Button>
                    </Link>
                    <Link to={'/qzuki-verse'}>
                        <Button className='toQzukiVerse'>QzukiVerse</Button>
                    </Link>
                </div>

                {
                    ifShowMobileSwiper ?
                        <Swiper
                            className="MobileCarousel"
                            modules={[Autoplay]}
                            slidesPerView='auto'
                            loopedSlides={5}
                            spaceBetween={10}
                            loop={true} // 是否循环
                            speed={4000}
                            simulateTouch={false}
                            allowTouchMove={false}
                            autoplay={{
                                delay: 0,
                                disableOnInteraction: false,
                            }}
                        >
                            {swiperSpeed[0].swiperImg.map((t, i) => {
                                return <SwiperSlide>
                                    <img src={t} alt="" className="Mobile-swiper-img" key={i} />
                                </SwiperSlide>
                            })}
                        </Swiper> : ''
                }
            </div>

            <div className="home-page-right flex-inline-row">
                {
                    swiperSpeed.map((item, index) => {
                        return <Swiper
                            key={index}
                            className="Carousel"
                            modules={[Autoplay]}
                            slidesPerView='auto'
                            loopedSlides={5}
                            spaceBetween={15}
                            loop={true} // 是否循环
                            speed={item.time}
                            simulateTouch={false}
                            allowTouchMove={false}
                            direction='vertical'
                            autoplay={{
                                delay: 0,
                                disableOnInteraction: false,
                                reverseDirection: item.ifreverseDirection
                            }}
                        >
                            {item.swiperImg.map((t, i) => {
                                return <SwiperSlide>
                                    <img src={t} alt="" className="swiper-img" key={i} />
                                </SwiperSlide>
                            })}
                        </Swiper>
                    })
                }
            </div>
        </div>
    }
}

export default withRouter(HomePage);
