import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {RootStore} from "./stores/rootStore";
import {ThemeProvider} from "./themes";

export const rootStore: RootStore = new RootStore();
(function () {
    let setRem = function () {
        // 移动端屏幕宽度
        let winWidth = document.documentElement.clientWidth;

        // 比率
        let rate = (winWidth>1920?1920:winWidth>560?winWidth:560) / 40;

        // 设置html元素的字体大小
        document.documentElement.style.fontSize = rate + "px"

    };
    setRem();
    window.onresize = function () {
        setRem();
    }
})();
ReactDOM.render(
  // <React.StrictMode> because of https://github.com/ant-design/ant-design/issues/22493
    <ThemeProvider>
        <App rootStore={rootStore}/>
    </ThemeProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
