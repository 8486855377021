import React, { useEffect, useState } from "react";
import './index.less'
import { CloseCircleOutlined } from '@ant-design/icons';
import { getRoutePath, MythRouteId } from "../../router-config";

interface IProps {
    item: any,
    ifModal: boolean,
    id?: number
    showSelectedStatus?: number
    selectDisplayArea?: (id: number) => void
    clearSelectedGraph?: (id: number) => void
    openGalleryModel?: (item: any) => void
}

const Treasure = ({ item, ifModal, id, selectDisplayArea, clearSelectedGraph, showSelectedStatus, openGalleryModel }: IProps) => {

    const treasureClick = () => {
        if (selectDisplayArea && (id || id === 0)) {
            selectDisplayArea(id)
        }

        if (openGalleryModel) {
            openGalleryModel(item.id)
        }
    }

    return <div key={id}
        className={`treasure-nft ${JSON.stringify(item) !== "{}" ? '' : 'occupyBitmap-shadow'} 
        ${showSelectedStatus === id && (showSelectedStatus || showSelectedStatus === 0) ? 'showSelectedStatus' : ''}
        ${!ifModal && item.thumbnail ? 'treasure-amplify' : ''}    
        `}
        onClick={treasureClick}
    >
        <div className="treasure-interface">
            {
                item.thumbnail ?
                    <img src={item.thumbnail} alt="" /> : ''
            }
            {
                ifModal && item.thumbnail ?
                    <CloseCircleOutlined className="icon" onClick={clearSelectedGraph && (id || id === 0) ? () => clearSelectedGraph(id) : () => { }} /> : ''
            }
        </div>
    </div >
};

export default Treasure;
