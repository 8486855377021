import React, { useState } from "react";
import "./index.less";
import musicQzuki from '../../images/qzuki/music-qzuki.png'
import musicLogo from '../../images/qzuki/music-logo.gif'
import Play from '../../images/qzuki/play.png'
import Pause from '../../images/qzuki/pause.png'

interface IProps {

}

const Music = () => {
    const [play, setPlay] = useState(false)

    const playMusic = () => {
        const audio = (document.getElementById('audio_player')) as HTMLAudioElement
        if (audio !== null) {
            if (audio.paused) {
                audio.play()
                setPlay(true)
            } else {
                audio.pause()
                setPlay(false)
            }
        }
    }

    return (
        <div className="music">
            <div className="logo"><img src={musicQzuki} alt="" /></div>
            <div className="flex-row align-center">
                <img src={musicLogo} alt="" className="music-logo" />
                <p>魔法大冒险</p>
            </div>

            <div onClick={playMusic} className="play-music">
                <img src={play ? Play : Pause} alt="" />
            </div>

            <audio id="audio_player" loop>
                <source src='https://qzuki.myth.art/audio/qzuki-music.mp3' type="audio/mpeg" />
            </audio>
        </div >
    );
}

export default Music;
