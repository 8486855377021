import {makeObservable, observable} from "mobx";
import {IWeb3StoreParams, Web3Store} from "./web3Store"
import {appEnv} from "../env";
import logger from "../logger";
import {ConnectorProvider, InjectedConnector, WalletConnectConnector} from "../web3Connectors";


export class RootStore  {
    public web3Store: Web3Store

    constructor() {
        const injected = new InjectedConnector(logger, appEnv.supportedNetworks);
        const walletConnect = new WalletConnectConnector(logger,appEnv.supportedNetworks);
        const connectorProvider = new ConnectorProvider(logger, [injected, walletConnect]);

        const web3Params:IWeb3StoreParams = { ...appEnv,logger: logger, connectorProvider: connectorProvider};
        this.web3Store = new Web3Store(web3Params);

        makeObservable(this, {
            web3Store: observable
        });

    }



}
