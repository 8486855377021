import React from 'react';
import { ReactNode, Suspense } from "react"
import { useIsMobile } from "../effects"

interface IProps {
    desktop: ReactNode
    mobile: ReactNode
}

export const LazyLoader = ({ desktop, mobile }: IProps) => {
	const isMobile = useIsMobile()
	return <Suspense fallback={<span />}> { isMobile ? mobile : desktop } </Suspense>
}