import { IRoute } from "./bean/RouteBean";
import QzukihomePage from './pages/HomePage'
import Gallery from './pages/Gallery'
import CollectorPage from './pages/CollectorPage'
import QzukiVerse from './pages/QzukiVerse'
import Hero from './pages/Hero'

export const enum MythRouteId {
    HomePage = 1, Gallery, CollectorPage, QzukiVerse, Hero
}

const routes: IRoute[] = [
    {
        id: MythRouteId.Hero,
        path: "/hero",
        component: Hero,
    },
    {
        id: MythRouteId.QzukiVerse,
        path: "/qzuki-verse",
        component: QzukiVerse,
    },
    {
        id: MythRouteId.CollectorPage,
        path: "/collector-page",
        component: CollectorPage,
    },
    {
        id: MythRouteId.Gallery,
        path: "/gallery",
        component: Gallery,
    },
    {
        id: MythRouteId.HomePage,
        path: "/",
        component: QzukihomePage,
    },

];

const formatRoutes = (routes: IRoute[]) => {
    let routeList = new Array(0);
    routes.forEach((route: IRoute) => {
        routeList.push({
            id: route.id,
            path: route.path,
            component: route.component,
        })
    })
    return routeList;
}

export function getRoutePath(routeId: number): string {
    const route: IRoute | undefined = routerList.find((route: IRoute) => {
        return route.id === routeId;
    });
    if (route) {
        return route.path;
    }
    return routes[1].path;
}

export const routerList = formatRoutes(routes);
export default routes;
