import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { Web3Store } from "../../stores/web3Store";
import { observer } from "mobx-react";
import { Button, Dropdown, Space, Menu, Drawer, message } from 'antd';
import type { MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ConnectedWallet } from "../connectWallet/connectedWallet";
import "./index.less";
import logo from '../../images/qzuki/logo.png'
import '../../styles/iconfont/iconfont.css'
import { Link } from "react-router-dom";
import { rootStore } from "../../index";
import { getMarketPlace, MarketPlace } from "../../constant/CommonConstant";
import { appEnv } from "../../env";
import { getRoutePath, MythRouteId } from "../../router-config";

const { Scrollbars } = require("react-custom-scrollbars");

interface IProps {
    web3Store: Web3Store;
    backgroundColor?: string
    padding?: string
    position?: boolean
}

const Impl = ({ web3Store, backgroundColor, padding, position }: IProps) => {
    type MenuItem = Required<MenuProps>['items'][number];
    const { Header } = Layout;
    const [btnState, setBtnState] = useState(false)
    const [boxState, setBoxState] = useState(false)
    const [firstDrawerPosition, setFirstDrawerPosition] = useState(false)

    const changeWidthShow = () => {
        if (window.innerWidth < 1200) {
            setBtnState(true)
        } else {
            setBtnState(false)
        }
    };

    useEffect(() => {
        changeWidthShow()
        window.addEventListener('resize', changeWidthShow);
        return () => {
            window.removeEventListener("resize", changeWidthShow);
        }
    })

    const popUpFrame = () => {
        setBoxState(!boxState)
    }

    const closeBoxState = (ifLoading: boolean) => {
        setBoxState(false)
        if (ifLoading) {
            setTimeout(
                () => window.location.reload(), 0
            )
        }
    }

    const goToPersonal = () => {
        if (web3Store.account) {
            const url = window.location.origin + getRoutePath(MythRouteId.CollectorPage) + "/edit?wallet=" + web3Store.account;
            window.open(url, '_self');
        } else {
            message.open({
                type: 'error',
                content: 'Please connect your wallet',
            });
        }
    }

    const getItem = (
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem => {
        return {
            key,
            icon,
            children,
            label,
            type,
        } as MenuItem;
    }

    const dropItems = () => {
        const item = []
        for (let i = 0; i < appEnv.marketPlace.length; i++) {
            item.push(
                {
                    key: i + 1,
                    label: (
                        <a target="_blank" rel="noopener noreferrer" href={getMarketPlace(appEnv.marketPlace[i].name)}>
                            {appEnv.marketPlace[i].name.toUpperCase()}
                        </a>
                    ),
                },
            )
        }
        return item
    }

    const dropGetItems = () => {
        const item = []
        for (let i = 0; i < appEnv.marketPlace.length; i++) {
            item.push(
                getItem(<a target="_blank" rel="noopener noreferrer" href={getMarketPlace(appEnv.marketPlace[i].name)}>{appEnv.marketPlace[i].name.toUpperCase()}</a>, 'c' + i + 1, ''),
            )
        }
        return item
    }

    const items: MenuProps['items'] = dropItems()

    const menuItems: MenuProps['items'] = [
        getItem(<div style={{ width: '100%', color: 'red', display: 'inline-flex', alignItems: 'center' }}><ConnectedWallet web3Store={web3Store} callback={() => setFirstDrawerPosition(!firstDrawerPosition)} /><div className="iconfont icon-qianbao" style={{ marginLeft: '-38px' }} /></div>, 'sub0', ''),

        getItem(<Link to={'/qzuki-verse'} onClick={() => closeBoxState(false)}>QZUKIVERSE</Link >, 'sub1', ''),

        getItem(<div onClick={goToPersonal}>
            COLLECTOR PAGE
        </div>, 'sub2', ''),

        getItem(<Link to={'/gallery'} onClick={() => closeBoxState(false)}>GALLERY</Link >, 'sub4', ''),

        getItem('BUY', 'grp', null,
            dropGetItems()),

        getItem(<a href="https://discord.gg/Ct9vGP5wVK" target="_blank" rel="noopener noreferrer">DISCORD <span className="iconfont icon-discord" style={{ float: 'right', marginRight: '17px' }} /></a>, 'grp2', null),
        getItem(<a href="https://twitter.com/QzukiOfficial" target="_blank" rel="noopener noreferrer">TWITTER <span className="iconfont icon-twitter" style={{ float: 'right', marginRight: '17px' }} /></a>, 'grp3', null)
    ];

    return (
        <>
            <Header className="layout-header flex-inline-row align-center" style={{ backgroundColor: `${backgroundColor ? backgroundColor : ''}`, padding: `20px ${padding ? padding : '0'}`, position: `${position ? 'fixed' : 'absolute'}` }}>
                <Link to={'/'} onClick={() => closeBoxState(true)}><Button className="first-btn"><img src={logo} alt="" className="logo" /></Button></Link>
                <div className="flex-inline-row align-center">
                    {
                        btnState ? <div className={`btn ${boxState ? 'changLineStatus' : ''}`} onClick={popUpFrame}>
                            <div className="line"></div>
                            <div className="line"></div>
                        </div> : <div className='flex-inline-row align-center'>
                            <Link to={'/qzuki-verse'}>
                                <Button>QZUKIVERSE</Button>
                            </Link>
                            <Button onClick={goToPersonal}>
                                COLLECTOR PAGE
                            </Button>
                            <Link to={'/gallery'}>
                                <Button>GALLERY</Button>
                            </Link>
                            <Dropdown menu={{ items }} placement="bottom" trigger={['click']}>
                                <Button>
                                    <Space>
                                        BUY
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                            <Button className="btn-discord"><a href="https://discord.gg/Ct9vGP5wVK" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}><span className="iconfont icon-discord"></span></a></Button>
                            <Button className="btn-twitter"><a href="https://twitter.com/QzukiOfficial" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}><span className="iconfont icon-twitter"></span></a></Button>
                            <Button className="myth-button"><ConnectedWallet web3Store={web3Store} /></Button>
                        </div>
                    }
                </div>
            </Header>
            <Drawer className={`header-drawer ${firstDrawerPosition ? 'drawer' : ''}`} placement="right" width={'80%'} onClose={popUpFrame} open={boxState} headerStyle={{ padding: '24px' }} bodyStyle={{ padding: '0 24px' }}>
                <Scrollbars>
                    <Menu mode="inline" items={menuItems} className='flex-row flex-direction-column align-left'>
                    </Menu >
                </Scrollbars>
            </Drawer>

            {/* {
                <TestNFTModal web3Store={rootStore.web3Store} visual={testModal} closeModel={closeTestNFTModal} />
            } */}
        </>
    );
};

const NavHeader = observer(Impl);

export default NavHeader;
