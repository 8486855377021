function GetEnvVar(name: string): string {
    const envName = `REACT_APP_${name}`;
    const env = process.env[envName] || undefined;
    if (env === undefined) {
        throw new Error(`${envName} not set`);
    }
    return env;
}


export interface INativeCurrency {
    name: string
    symbol: string
    decimals: number
}
export interface IMarketplace {
    name: string
    collectionUrl: string
}

export interface IActionConfig {
    actions: IAction[];
}

export interface IAction {
    themeId: number,
    nftTypeId: number,
    actionId: number
}

export interface INetworkConfig {
    name: string
    chainId: number
    blockExplorer: string
    rpcUrl: string
    nativeCurrency?: INativeCurrency,
    mythNFTManagerAddr: string,
    actionConfig: IActionConfig,
    qzukiContractAddr: string,
    azukiContractAddr: string,
    beanzContractAddr: string,
    subGraphApiUrl: string
}

export interface IQzukiServer {
    login: {
        getNonce: string,
        requestLogin: string,
    },
    personal: {
        getPersonalInfo: string,
        getAllBadges: string,
        getAllMyQzukis: string,
        updateDisplayBanner: string,
        updateDisplayQzukis: string,
        updateUserName: string,
    },
    hero: {
        submitHeroStory: string,
        getUserLastHeros: string,
        getUserLastQzukiHero: string,
        addSubmitCounts: string,
        getSubmitCounts: string,
        getMateOccupations: string,
    },
    common: {
        getOwner: string,
    }
}

interface IEnv {
    qzukiServerApis: IQzukiServer;
    supportedNetworks: INetworkConfig[]
    marketPlace: IMarketplace[]
}

const envStr = GetEnvVar('ENV')

let appEnv: IEnv
appEnv = JSON.parse(envStr)

export { appEnv }
