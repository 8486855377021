import React, { useEffect, useState } from "react";
import './index.less';
import noData from '../../images/qzuki/nodata.png'

interface IProps {
    noDatatext: string
    textSize?: string
    padding?: string
}

const NoData = ({ noDatatext, textSize, padding }: IProps) => {
    return (
        <div className="noData" style={{ padding: `${padding ? padding : '30px'} 0` }}>
            <img src={noData} alt="" />
            <p style={{ fontSize: `${textSize ? textSize : '20px'}` }}>{noDatatext}</p>
        </div>
    );
};

export default NoData;
