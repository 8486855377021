import { Modal } from "antd";
import { rootStore } from "../index";
import logger from "../logger";

let tokenErrorShowing = false;

export const executeTokenProcess = async () => {
    try {
        const result = await rootStore.web3Store.signatureAsync();
        if (result) {
            window.location.reload();
        }
    } catch (e) {
        logger.error(e);
    }
};

export function handleTokenInvalid() {
    if (!tokenErrorShowing) {
        tokenErrorShowing = true;
        Modal.info({
            title: 'Welcome to Qzuki',
            content: 'Connecting your wallet',
            okText: "Sign",
            closable: true,
            className: 'sign',
            onOk: close => {
                tokenErrorShowing = false;
                close();
                executeTokenProcess();
            }
        })
    }
}
