import React, { useEffect, useState } from "react";
import './index.less'
import { Tag } from 'antd';
import arbitrumLogo from '../../images/qzuki/arbitrum-logo.png'
import { IPersonalInfoBanners } from "../../bean/ResponseBean";

interface IProps {
    banner: IPersonalInfoBanners
    index: number
    ifSwitchBannerModal?: boolean
    switchBannerId?: number
    ifModal?: boolean
    selectBanner?: (banner: IPersonalInfoBanners, id: number) => void
}

const Banner = ({ banner, ifModal, selectBanner, index, switchBannerId, ifSwitchBannerModal }: IProps) => {
    const [bannerNumberOfTimes, setBannerNumberOfTimes]: any[] = useState([banner])

    useEffect(() => {
        let number = []
        let a = 0

        while (a < banner.balance) {
            number.push(banner)
            a++
        }

        setBannerNumberOfTimes(number)
    }, [banner])

    return <div className="banner-img" key={index}>
        {
            banner.balance > 1 ?
                ifModal ?
                    <div onClick={selectBanner ? () => selectBanner(bannerNumberOfTimes[0], index) : () => { }}>
                        <div style={{ position: 'relative' }}>
                            <div style={{ boxShadow: `${index === switchBannerId && ifSwitchBannerModal ? '0 0 0 3px #f8e51a, 0 0 2px 3.5px rgba(0, 0, 0, 0.2)' : ''}` }}>
                                {
                                    bannerNumberOfTimes.slice(0, 3).map((item: any, index: any) => {
                                        return <img src={item.thumbnail} alt='' className="overlapping-drawing" />
                                    })
                                }
                            </div>
                            <Tag className="banner-num" style={{ transform: `${ifModal ? 'scale(0.6)' : ''}` }}>{banner.balance}</Tag>
                            <img src={arbitrumLogo} alt="" className="polygon-logo" style={{ width: `${ifModal ? '18px' : ''}` }}/>
                        </div>
                        <div className="banner-information flex-row space-between">
                            <p>{banner.name}</p>
                            <div className={`tag ${banner.rarity === 'Rare' ? 'tag-rare' : banner.rarity === 'Golden' ? 'tag-golden' : 'tag-mythical'}`} >{banner.rarity}</div>
                        </div>

                    </div>
                    : <>
                        <div style={{ position: 'relative' }}>
                            <div>
                                {
                                    bannerNumberOfTimes.slice(0, 3).map((item: any, index: any) => {
                                        return <img src={item.thumbnail} alt='' className="overlapping-drawing" />
                                    })
                                }
                            </div>
                            <Tag className="banner-num">{banner.balance}</Tag>
                            <img src={arbitrumLogo} alt="" className="polygon-logo" />
                        </div>
                        <div className="banner-information flex-row space-between">
                            <p>{banner.name}</p>
                            <div className={`tag ${banner.rarity === 'Rare' ? 'tag-rare' : banner.rarity === 'Golden' ? 'tag-golden' : 'tag-mythical'}`} >{banner.rarity}</div>
                        </div>

                    </>
                :
                <div onClick={selectBanner ? () => selectBanner(bannerNumberOfTimes[0], index) : () => { }}>
                    <img src={banner.thumbnail} alt="" className="img" style={{ boxShadow: `${index === switchBannerId && ifSwitchBannerModal ? '0 0 0 3px #f8e51a, 0 0 2px 3.5px rgba(0, 0, 0, 0.2)' : ''}` }} />
                    <div className="banner-information flex-row space-between">
                        <p style={{ fontSize: `${ifModal ? '12px' : ''}` }}>{banner.name}</p>
                        <div className={`tag ${banner.rarity === 'Rare' ? 'tag-rare' : banner.rarity === 'Golden' ? 'tag-golden' : 'tag-mythical'}`} style={{ fontSize: `${ifModal ? '12px' : ''}` }}>{banner.rarity}</div>
                    </div>
                    <img src={arbitrumLogo} alt="" className="polygon-logo" style={{ width: `${ifModal ? '18px' : ''}` }} />
                </div>
        }
    </div>
};

export default Banner;
