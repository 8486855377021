import {Modal} from "antd";

let errorShowing = false;

export function handleMythServerError(errMsg: string) {
    if (!errorShowing) {
        errorShowing = true;
        Modal.error({
            title: 'Error',
            content: errMsg,
            className: 'sign',
            onOk:close => {
                errorShowing = false;
                close();
            }
        });
    }
}
