import React from 'react';
import './App.less';
import Routing from './Routing';
import { RootStore } from "./stores/rootStore";
import './fonts/font.less'


class App extends React.Component<{ rootStore: RootStore }, {}> {

  render = () => (<Routing rootStore={this.props.rootStore} />)

}

export default App;
