const ZERO_ADDR = '0x0000000000000000000000000000000000000000'
export const UNKNOWN = "unknown";


// eslint-disable-next-line
export  const isNonZeroAddress = function(address:string): boolean {
  if(!address || address === ZERO_ADDR) { return false }
  return true;
};

// eslint-disable-next-line
export const isZeroAddress = function(address:string): boolean {
  return address === ZERO_ADDR
};

const CHUNK_LENGTH_DEFAULT = 4;
// eslint-disable-next-line
export const toMasskedAddress = function(address:string,chunkLength?: number): string {
  const cLen = chunkLength || CHUNK_LENGTH_DEFAULT

  if (!address || address.length <= cLen) {
		return address as string
	}

  const start = address.substring(0, cLen);
  const end = address.substring(address.length - cLen);
  return `${start}...${end}`
};

