import React, { useEffect, useState } from "react";
import './index.less'
import { Tooltip } from 'antd';
import { IAllBadges } from "../../bean/ResponseBean";

interface IProps {
    badge: IAllBadges
    selectBadge?: (item: IAllBadges) => void
    noModalselectBadge?: (item: IAllBadges) => void
}

const Badge = ({ badge, selectBadge, noModalselectBadge }: IProps) => {

    return <>
        {
            selectBadge ?
                <div className="badge" onClick={() => selectBadge(badge)}>
                    < div className="mirror-effect" > <img src={badge.lightImage ? badge.lightImage : badge.darkImage} alt="" /></div >
                </div >
                : <Tooltip placement="top" title={badge.name} overlayInnerStyle={{ borderRadius: '20px', fontFamily: 'pixelfont' }}>
                    <div className="badge" onClick={noModalselectBadge ? () => noModalselectBadge(badge) : () => { }}>
                        < div className="mirror-effect" > <img src={badge.lightImage ? badge.lightImage : badge.darkImage} alt="" /></div >
                    </div >
                </Tooltip>
        }
    </>
};

export default Badge;
