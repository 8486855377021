import axios, {AxiosResponse} from "axios"
import {ILogger} from "../logger"
import {IGraphResponse, IGraphNFTBalance} from "../bean/ResponseBean";


const NFTBalanceQuery = `{
    tokenAddress
    tokenId
}`;

export class SubGraphApi {

    private readonly _logger: ILogger;
    public readonly subgraphUrl: string

    constructor(logger: ILogger, subgraphUrl:string) {
        this.subgraphUrl = subgraphUrl;
        this._logger = logger
    }

    private async execQuery<TResponseData>(baseAddress:string,q: string, calledMethod: string, signal?: AbortSignal): Promise<TResponseData | undefined> {
        type responseType = IGraphResponse<TResponseData>
        const method = `${SubGraphApi.name}.${calledMethod}`
        this._logger.trace(`${method} graph query: ${q}`)
        //to fix CROS for subgraph
        axios.defaults.withCredentials = false;
        try{
            const response = await axios.post<responseType, AxiosResponse<responseType>>(baseAddress, {
                timeout: 30000,
                query: q,
                signal: signal
            });
            if (response.data.errors && response.data.errors.length) {
                this._logger.error(`${method}: ${response.data.errors[0].message}`)
            }

            return response.data.data
        }catch (e) {
            console.log(e)
        }finally {
            //reset,to avoid server api missing cookie when request
            axios.defaults.withCredentials = true;
        }
    }


    public async fetchNFTBalance(
        account: string
    ): Promise<IGraphNFTBalance[]|undefined> {
        const userFilter = !!account ? `user:"${account.toLowerCase()}"` : '';
        const q = `
      {
        nfts(first: 1000, skip: 0, where: {
          ${userFilter}
        })
        ${NFTBalanceQuery}
      }`;
        const response = await this.execQuery<{nfts: IGraphNFTBalance[] }>(this.subgraphUrl,q, this.fetchNFTBalance.name);
        return response?.nfts;
    }

}
